<template>
  <div>
    <b-navbar style="border-bottom: 1px solid #7957d5">
      <template #start>
        <b-navbar-item><strong>Generador de códigos QR TIDECS.COM</strong></b-navbar-item>
      </template>
      <template #end>
        <b-navbar-item tag="div">
          <div class="buttons">
            <a href="https://tidecs.com/" class="button is-danger" target="_blank">
              <b-icon icon="help"></b-icon>
              <strong>TIDECS.COM</strong>
            </a>
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>
    <section class="p-4">
      <div class="columns">
        <div class="card column is-8">
          <div class="buttons">
      <a href="" class="button" @click.prevent="cambiarTipoEntrada('textarea')">
        <b-icon icon="web"></b-icon>
        <strong>URL</strong>
      </a>
      <a href="" class="button" @click.prevent="cambiarTipoEntrada('input')">
        <b-icon icon="text"></b-icon>
        <strong>TEXTO</strong>
      </a>
    </div>
          <b-field
          
            label="Contenido"
            message="Puede ser una URL, página de Facebook, texto, etcétera"
          >
          <b-input
      @keyup.native="actualizarCodigoQr()"
      v-model="detallesQr.value"
      ref="textareaContenido"
      :type="tipoEntrada"
    ></b-input>
          </b-field>


        </div>
        <div class="column is-one-third">
          <div class="card has-text-centered">
            <header class="card-header">
              <p class="card-header-title">Previsualización</p>
            </header>
            <div class="card-content">
              <div class="content">
                <img alt="Código QR generado por la app" id="codigo" />
                <br />
                <b-field grouped>
            <b-field label="Calidad">
              <b-select
                @input="actualizarCodigoQr()"
                placeholder="Seleccione"
                v-model="detallesQr.level"
              >
                <option value="L">Baja</option>
                <option value="M">Media</option>
                <option value="Q">Alta</option>
                <option value="H">Máxima</option>
              </b-select>
            </b-field>
            <b-field
              label="Tamaño de la imagen"
              message="Modifique el tamaño de su prefeencia"
            >
              <b-slider
                v-model="detallesQr.size"
                :min="10"
                @change="actualizarCodigoQr()"
                :max="1000"
              ></b-slider>
            </b-field>
          </b-field>
                <b-button @click="descargar()" type="is-success"
                  >Descargar</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
const colorPorDefecto = "#000000",
  colorDeFondoPorDefecto = "#ffffff",
  nivelCorrecionAlto = "H";
import Logo from "./assets/logo.png";
import QRious from "qrious";
export default {
  name: "app",
  data() {
    return {
      logo: Logo,
      detallesQr: {
        value: "",
        foreground: colorPorDefecto,
        background: colorDeFondoPorDefecto,
        size: 200,
        level: nivelCorrecionAlto,
      },
      qr: null,
      colores: {
        hex: colorPorDefecto,
      },
      coloresFondo: {
        hex: colorDeFondoPorDefecto,
      },
    };
  },
  methods: {
    descargar() {
      const a = document.createElement("a");
      a.href = document.querySelector("#codigo").src;
      a.download = "Código QR.png";
      a.click();
    },
    actualizarCodigoQr() {
      this.qr.set({
        value: this.detallesQr.value,
        foreground: this.detallesQr.foreground,
        background: this.detallesQr.background,
        size: this.detallesQr.size,
        backgroundAlpha: this.detallesQr.backgroundAlpha,
        level: this.detallesQr.level,
      });
    },
    cambiarTipoEntrada(tipo) {
      this.tipoEntrada = tipo;
    },
  },
  mounted() {
    this.qr = new QRious({
      element: document.querySelector("#codigo"),
    });
    this.actualizarCodigoQr();
    this.$refs.textareaContenido.focus();
  },
  created() {
    this.tipoEntrada = 'textarea';
  },
};
</script>